<template>
  <v-container style="max-width: 1280px;margin-top: 10px">
    <v-row>
      <v-col cols="4" class="mb-0 pb-0">
        <p style="cursor: pointer;" @click="goToHome">ホーム</p>
        <div class="foot-style">秋葉原高価買取専門<br>東京都公安委員会 第301032008205号</div>
      </v-col>
      <v-col cols="4" style="cursor: pointer" class="mb-0 pb-0">
        <p style="cursor: pointer" @click="goToProtect">個人情報保護方針</p>
        <p style="cursor: pointer" @click="goToCompany">会社案内</p>
      </v-col>
      <v-col cols="4" class="mb-0 pb-0">
        <p class="pa-0 ma-0">〒 101-0023 東京都千代田区神田松永町11 <br>AＴ第一ビル5階</p>
        <p class="pa-0 ma-0">☎️ 03-6262-9897</p>
        <p class="pa-0 ma-0">✉️ minamoto@kaitoriakiba.com</p>
      </v-col>
      <v-col class="mt-0 pt-0 pb-0" cols="12">
        <p class="pa-0 ma-0">©2024 株式会社 MINAMOTO All Rights Reserved.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Foot",
  methods: {
    goToHome() {
      if (this.$route.path !== '/') {
        this.$router.push({
          name: "Home"
        });
      }
    },
    goToProtect() {
      if (this.$route.path !== '/Protect') {
        this.$router.push({
          name: "Protect"
        });
      }
    },
    goToCompany(){
      if (this.$route.path !== '/Company') {
        this.$router.push({
          name: "Company"
        });
      }
    }
  }
}
</script>

<style scoped>
.foot-style{
  font-size: 14px;
}
</style>
