<template>
  <v-container style="width: 100%;margin-top: 30px" v-if="goodDetail != null">
    <div style="position: relative;">
      <div width="275px" height="260px" style="display: flex;justify-content: center;align-items: center;">
        <img width="210px" height="210px" :src="urlImgHead + goodDetail.picsrc">
      </div>
      <p class="good_name"
         style="padding-left:50px;margin-bottom:0px !important;font-size:15px;height: 70px;overflow:hidden;">
        {{ goodDetail.goodname }}</p>
      <p style="color: black;text-align: center">
        {{ goodDetail.goodsubname }}
      </p>
      <p style="color: red;text-align: center">
        <b style="font-size: 16px">新品：{{ showprice }}</b>
        <br>
        <b style="font-size: 16px">中古：{{ showOldPrice }}</b>
      </p>
    </div>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import {fixPrice} from "@/utils/common";
import {urlImgHead} from "@/domain/RestClient"

export default {
  name: "GoodShow",
  props: {
    goodDetail: Object
  },
  data() {
    return {
      urlImgHead
    }
  },
  computed: {
    ...mapGetters({
      goodItems: 'cart/goodItems',
    }),
    showOldPrice() {
      if (this.goodDetail == null) {
        return "買取対象外"
      } else if (Number(this.goodDetail.oldprice) == -1) {
        return "買取対象外"
      } else if (Number(this.goodDetail.oldprice) == 0) {
        return "お問い合わせ"
      } else {
        return "￥"+fixPrice(this.goodDetail.oldprice)
      }
    },
    showprice() {
      if (this.goodDetail == null) {
        return "買取対象外"
      } else if (Number(this.goodDetail.price) == -1) {
        return "買取対象外"
      } else if (Number(this.goodDetail.price) == 0) {
        return "お問い合わせ"
      } else {
        return "￥"+fixPrice(this.goodDetail.price)
      }
    },
  },
}
</script>

<style scoped>

</style>
