<template>
  <v-container fluid>
    <v-row justify="center" class="mt-3">
      <v-container style="max-width: 1280px">
        <v-row cols="12" justify="center">
          <v-col cols="2">
            <SideCategoryMenu></SideCategoryMenu>
          </v-col>
          <v-col cols="8">
            <v-text-field
                height="40px"
                dense
                solo
                label="キーワード・JANコード"
                clearable
                hide-details
                v-model="searchCondition"
                @keyup.enter="goodSearch"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
                height="40px"
                width="180px"
                color="red"
                dark
                @click="goodSearch"
            >
              <v-icon style="color: white !important;">
                mdi-magnify
              </v-icon>
              <div style="color: white;font-weight: bold;">検索</div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row justify="center" style="margin-top: 30px">
      <v-container style="max-width: 1280px">
        <v-row cols="12">
          <v-col cols="12" class="pa-0">
            <v-row>
              <v-col align="center" cols="12">
                <p class="pl-3" style="font-size: 30px"><b>買取対象商品</b></p>
              </v-col>
              <v-col cols="3"></v-col>
              <v-col align="center" cols="6">
                <p>買取価格は在庫状況や相場により変動する場合がございます。ご了承ください。
                  また、掲載商品以外も多数買取しておりますので、お気軽にお問い合わせください。</p>
              </v-col>
              <v-col cols="3">
                <v-btn
                    rounded
                    color="primary"
                    dark
                    @click="toggleViewMode">表示方法変更
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" v-if="goodSearchList.length>0 && viewMode">
              <v-col v-for="goodDetail in goodSearchList" :key="goodDetail.id" cols="3" class="pa-0 ma-0">
                <GoodShow :goodDetail="goodDetail"></GoodShow>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" v-if="goodSearchList.length>0 && !viewMode">
              <v-col cols="12">
                <v-simple-table>
                  <thead>
                  <tr>
                    <th class="text-left">商品名</th>
                    <th class="text-left">JANコード</th>
                    <th class="text-left">新品価格</th>
                    <th class="text-left">中古価格</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="goodDetail in goodSearchList" :key="goodDetail.id">
                    <td>{{ goodDetail.goodname }}</td>
                    <td>{{ goodDetail.goodsubname }}</td>
                    <td style="color: red">{{ showPrice(goodDetail.price) }}</td>
                    <td style="color: red">{{ showPrice(goodDetail.oldprice) }}</td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import SideCategoryMenu from '../components/SideCategoryMenu'
import GoodShow from '../components/CommonUtils/GoodShow'
import {getGoodSearch} from "@/domain/api/good";
import {mapGetters} from "vuex";
import {fixPrice} from "@/utils/common";


export default {
  name: 'GoodSearch',
  components: {
    SideCategoryMenu,
    GoodShow
  },

  data: () => ({
    goodSearchList: [],
    searchCondition: null,
    viewMode: true
  }),
  async mounted() {
    if (this.condition.id == null && this.condition.parentId == null && (this.condition.keyword == null || this.condition.keyword === "")) {
      console.log(this.condition)
    } else {
      this.goodSearchList = await getGoodSearch(this.condition);
    }
  },
  computed: {
    ...mapGetters({
      condition: 'searchcondition/condition',
    }),
  },
  methods: {
    showPrice(price) {
     if (Number(price) === -1) {
        return "買取対象外"
      } else if (Number(price) === 0) {
        return "お問い合わせ"
      } else {
        return "￥"+fixPrice(price)
      }
    },
    toggleViewMode() {
      this.viewMode = !this.viewMode
    },
    goodSearch() {
      this.$store.dispatch('searchcondition/setCondition', {
        condition: {
          id: null,
          parentId: null,
          keyword: this.searchCondition
        }
      });
      if (this.$route.path === "/GoodSearch") {
        window.location.reload();
      } else {
        this.$router.push({
          name: "GoodSearch"
        });
      }
    }
  }
};
</script>
<style>

.title-self {
  font-size: 30px;
}

.body-self {
  font-size: 20px;
}
</style>
