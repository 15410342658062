<template>
  <v-container fluid>
    <v-row justify="center" class="mt-3">
      <v-container style="max-width: 1280px">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                <p class="title-self"><b>中古パソコン買取（Win＆Mac）</b></p>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <p class="body-self" style="color: red;text-decoration: underline;font-size: 20px !important;">
                  <b>秋葉原最強買取！法人様、複数持込大歓迎♪</b></p>
              </v-col>
              <v-col cols="12">
                <p class="body-self" style="font-size: 17px !important;">不要なノートパソコン、デスクトップパソコン、タブレットPCはございませんか？</p>
                <p class="body-self" style="font-size: 17px !important;">
                  メーカー不問、高価買取致します。使わなくなった・買い替えなど、お気軽にご連絡ください。</p>
              </v-col>
              <v-col cols="12">
                <p class="body-self" style="color: red;font-size: 20px !important;"><b>人気シリーズ</b></p>
              </v-col>
              <v-col cols="3">
                <PopularSeriesButton label="MacBook Air"></PopularSeriesButton>
              </v-col>
              <v-col cols="3">
                <PopularSeriesButton label="Surface Laptop"></PopularSeriesButton>
              </v-col>
              <v-col cols="3">
                <PopularSeriesButton label="Surface Pro"></PopularSeriesButton>
              </v-col>
              <v-col cols="3"></v-col>
              <v-col cols="3">
                <PopularSeriesButton label="Let's note"></PopularSeriesButton>
              </v-col>
              <v-col cols="3">
                <PopularSeriesButton label="iPad"></PopularSeriesButton>
              </v-col>
              <v-col cols="3">
                <PopularSeriesButton label="iPad Air"></PopularSeriesButton>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-5">
          <v-card-title>
            <v-row>
              <v-col>
                <p class="title-self"><b>故障・破損パソコン買取（Win＆Mac）</b></p>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-img src="../assets/6.jpg" alt="6" contain="false" cover max-height="550px"></v-img>
            </v-row>
          </v-card-text>

        </v-card>
        <v-card class="mt-5">
          <v-card-text>
            <v-row dense>
              <v-img src="../assets/shang.jpg" alt="6" contain="false" cover max-height="350px"></v-img>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-5">
          <v-card-text>
            <v-row dense>
              <v-img src="../assets/yamato.jpg" alt="6" contain="false" cover max-height="350px"></v-img>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import PopularSeriesButton from "@/components/PopularSeriesButton.vue";

export default {
  name: 'OldComputer',
  components: {
    PopularSeriesButton
  },

  data: () => ({}),
  async mounted() {

  },
  methods: {}
};
</script>
<style>

.title-self {
  font-size: 30px;
}

.body-self {
  font-size: 20px !important;
}

</style>
