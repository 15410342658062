<template>
  <v-container fluid>
    <v-row justify="center" class="mt-3">
      <v-container style="max-width: 1280px">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                <p class="title-self"><b>メール査定</b></p>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="3">
                <v-text-field
                    outlined
                    placeholder="お名前*"
                    prepend-inner-icon="mdi-face-man"
                    v-model="userName"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    outlined
                    placeholder="メールアドレス*"
                    prepend-inner-icon="mdi-email-outline"
                    v-model="mail"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    outlined
                    placeholder="電話番号*"
                    prepend-inner-icon="mdi-phone"
                    v-model="phone"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    outlined
                    placeholder="タイトル*"
                    prepend-inner-icon="mdi-format-title"
                    v-model="title"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    class="custom-textarea"
                    outlined
                    placeholder="お問い合わせ内容"
                    prepend-inner-icon="mdi-chat"
                    height="300px"
                    v-model="textBody">

                </v-textarea>
              </v-col>
              <v-col cols="12" align="center">
                <v-btn
                    width="200px"
                    dark
                    color="primary"
                    @click="sendMail"
                >
                  送信
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import {notEmpty} from "@/utils/common";
import {post} from "@/domain/RestClient";

export default {
  name: 'SendMail',

  data: () => ({
    userName: null,
    mail: null,
    phone: null,
    title: null,
    textBody: null
  }),
  async mounted() {

  },
  methods: {
    async sendMail() {
      if (!notEmpty(this.userName)) {
        alert("名前入力してください。")
        return
      }
      if (!notEmpty(this.mail)) {
        alert("メール入力してください。")
        return
      }
      if (!notEmpty(this.phone)) {
        alert("携帯番号入力してください。")
        return
      }
      if (!notEmpty(this.title)) {
        alert("タイトル入力してください。")
        return
      }
      if (!notEmpty(this.textBody)) {
        alert("内容入力してください。")
        return
      }
      try{
        await post("public/sendMessage", {
          name: this.userName,
          customMail: this.mail,
          phoneNumber: this.phone,
          subject: this.title,
          bodyContext: this.textBody
        })
        alert("送信成功しました。")
      }catch (e) {
        alert("送信失敗しました。電話で問い合わせしてください。")
      }
    }
  }
};
</script>
<style>

.title-self {
  font-size: 30px;
}

.body-self {
  font-size: 18px;
}

.custom-textarea .v-input__control .v-input__prepend-inner .v-icon {
  margin-top: -10px; /* 调整这个值来移动图标 */
}

</style>
